<template>
  <div class="container-renderizador">
    <div class="container-fluid" v-if="carregouPaginas">

        <div class="row"
        v-for="(comp, index) in componentes" 
        :key="index"
        :id="comp && comp.posicao !== undefined && comp.posicao !== null? `parte-${comp.posicao}`: null"
        >
            <component
                :is="comp.tipo"
                v-bind:key="comp.id"
                :componente="comp"
                :id_comp="comp.id"
            ></component>
        </div>
        
    </div>

    <div class="container-fluid" style="min-height: 100vh;" v-else>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// Mobile
import MbBannerPrincipal from '@/mobile/components/page-components/MbBannerPrincipal.vue';
import MbImgEsqDir from '@/mobile/components/page-components/MbImgEsqDir.vue';
import MbFormAgentes from '@/mobile/components/page-components/MbFormAgentes.vue';
const MbFormContato = () => import('@/mobile/components/page-components/MbFormContato.vue');
// import MbFormContato from '@/mobile/components/page-components/MbFormContato.vue';
import MbListaIcones from '@/mobile/components/page-components/MbListaIcones.vue';
import MbFaixaTextoImagem from '@/mobile/components/page-components/MbFaixaTextoImagem.vue';
import MbRowsCentralizadas from '@/mobile/components/page-components/MbRowsCentralizadas.vue';
import MbColunasBotaoImg from '@/mobile/components/page-components/MbColunasBotaoImg.vue';
import MbFaq from '@/components/page-components/Faq.vue';
// Responsivos
import BannersHome from '@/components/home-components/BannersHome.vue'
// Novos para home
import MbLinhaProdutos from '@/mobile/components/home-components/MbLinhaProdutos.vue'
import MbValidaBoleto from '@/mobile/components/home-components/MbValidaBoleto.vue'
import MbUltimasNoticias from '@/mobile/components/home-components/MbUltimasNoticias.vue'
import MbImgDireitaHome from '@/mobile/components/home-components/MbImgDireitaHome.vue'
import MbImgEsquerdaHome from '@/mobile/components/home-components/MbImgEsquerdaHome.vue'
//--
import MapaGoogle from '@/components/page-components/MapaGoogle.vue';

export default {
  name: 'MbRenderizadorPage',
  components: {
    "BANNER_PADRAO": MbBannerPrincipal,
    "IMG_DIREITA": MbImgEsqDir,
    "LISTA_ICONES": MbListaIcones,
    "LISTA_ICONES_ESQ": MbListaIcones,
    "IMG_ESQUERDA": MbImgEsqDir,
    "AGENTES": MbFormAgentes,
    "CONTATO": MbFormContato,
    "ROWS_CENTRALIZADAS": MbRowsCentralizadas,
    "IMAGEM_FAIXA_TEXTO": MbFaixaTextoImagem,
    "COLUNAS_BOTAO_IMG": MbColunasBotaoImg,
    "FAQ": MbFaq,
    "MAPA_GOOGLE": MapaGoogle,
    "LISTA_CARROSSEL": MbListaIcones,
    "BANNER_HOME": BannersHome,
    "LINHA_PRODUTOS": MbLinhaProdutos, 
    "VALIDA_BOLETO": MbValidaBoleto,
    "ULTIMAS_NOTICIAS": MbUltimasNoticias,
    "IMG_DIREITA_HOME": MbImgDireitaHome,
    "IMG_ESQUERDA_HOME": MbImgEsquerdaHome,
  },
  props: {
      url: {
          type: String,
          default: '/',
      }
  },
  data: function() {
      return {
          id_pagina: 0,
      }
  },
  computed: {
    ...mapGetters({
        listaPaginas: 'paginas/paginas',
    }),
    pagina: function() {
        let pagina = this.listaPaginas? 
                        this.listaPaginas
                            .find(pagina => {
                                return pagina.url_setor === this.url;
                            })
                        : {};

        return pagina; 
    },
    componentes: function() {
        if(this.pagina) {
            return this.pagina.componentes? this.pagina.componentes: [];
        } else {
            return [];
        }
    },
    carregouPaginas() {
        if(!this.listaPaginas || this.listaPaginas.length == 0) {
            return false
        }
        return true
    },
  },
  watch: {
    pagina(novaPagina) {
        if(novaPagina && novaPagina.titulo) {
            document.title = `Omni Banco & Financeira - ${novaPagina.titulo}`
        }
    }
  },
  created() {
  },
    mounted() {
    if(this.pagina && this.pagina.titulo) {
        document.title = `Omni Banco & Financeira - ${this.pagina.titulo}`
    }
  },
}
</script>

<style scoped>
.container-renderizador {
    display: grid;
    max-width: 100vw;
}
</style>