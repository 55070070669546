<template>
    <div class="mb-img-esq-dir-container" :style="{ 'background-color': corFundo }">
        <div class="container-fluid" style="min-height: 150px;" v-if="componente">

            <div class="row" v-if="componente.url_img_mb">
                <div class="col-12">
                    <b-card-img height="100%" 
                                :src="urlImagemMb" alt="Imagem" ></b-card-img>
                </div>
            </div>
            <div class="row" v-else>
                <div class="col-12">
                    <b-card-img height="100%" 
                                :src="urlImagem" alt="Imagem" ></b-card-img>
                </div>
            </div>

            <div class="row px-3">

                <div class="container">
                    <div class="row" v-if="componente.titulo">
                        <h3 class="mb-img-esq-dir-texto-centralizado" :style="{ 'color': corTitulo }">{{ componente.titulo }}</h3>
                    </div>
                    <div class="row" v-if="componente.sub_titulo">
                        <h5 class="mb-img-esq-dir-texto-centralizado" :style="{ 'color': corSubTitulo }">{{ componente.sub_titulo }}</h5>
                    </div>
                    <div class="row px-3 my-3" v-if="componente.texto">
                        <span v-html="componente.texto" :style="{ 'color': corTexto }" class="mx-auto conteudo-html-components"></span>
                    </div>
                    <div class="row" v-if="temBotao1">
                        <botao-m @click="redireciona(componente.url_botao_1)" :style="{ 'background-color': corBotao1 }" class="mb-img-esquerda-botao mx-auto"> {{textoBotao1}} </botao-m>
                    </div>
                    <div class="row" v-if="temBotao2">
                        <botao-m @click="redireciona(componente.url_botao_2)" :style="{ 'background-color': corBotao2 }" class="mb-img-esquerda-botao mx-auto"> {{textoBotao2}} </botao-m>
                    </div>
                </div>

            </div>

        </div>
    </div>
</template>
<script>
import { temasFactory, getCor } from '@/assets/js/SharkUtils';
import { LISTA_CORES } from '@/assets/js/dicionarioPaginas';
import { redirecionadorExterno } from '@/assets/js/SharkUtils';
import BotaoM from '@/components/botoes/BotaoM';

export default {
    name: 'ImgEsquerda',
    components: {
        BotaoM,
    },
    props: {
        id_comp: Number,
        componente: Object,
    },
    computed: {
        tema() {
            if(this.componente) {
                return temasFactory(this.componente.tema);
            }
            return null;
        },
        corFundo() {
            if(this.componente) {
                if(this.componente.tema) {
                    let corLista = getCor(this.componente.tema);
                    if(corLista) {
                        return corLista.var_hexa;
                    }
                }
            }
            return '#fff';
        },
        corTexto() {
            return '#000';
        },
        temBotao1() {
            if(this.componente) {
                if(this.componente.txt_botao_1) {
                    return this.componente.txt_botao_1.length > 0 ? true: false;
                }
            }
            return false;
        },
        textoBotao1() {
            if(this.componente) {
                if(this.componente.txt_botao_1) {
                    return this.componente.txt_botao_1
                }
            }
            return 'Clique aqui!';
        },
        corBotao1() {
            if(this.componente) {
                if(this.componente.cor_botao_1) {
                    let corLista = getCor(this.componente.cor_botao_1);
                    if(corLista) {
                        return corLista.var_hexa;
                    }
                }
            }
            return 'var(--laranja-omni)';
        },
        temBotao2() {
            if(this.componente) {
                if(this.componente.txt_botao_2) {
                    return this.componente.txt_botao_2.length > 0 ? true: false;
                }
            }
            return false;
        },
        textoBotao2() {
            if(this.componente) {
                if(this.componente.txt_botao_2) {
                    return this.componente.txt_botao_2
                }
            }
            return 'Clique aqui!';
        },
        corBotao2() {
            if(this.componente) {
                if(this.componente.cor_botao_2) {
                    let corLista = getCor(this.componente.cor_botao_2);
                    if(corLista) {
                        return corLista.var_hexa;
                    }
                }
            }
            return 'var(--laranja-omni)';
        },
        urlImagem() {
            if(this.componente) {
                if(this.componente.url_img) {
                    return this.componente.url_img;
                }
            }
            return require('../../../assets/images/placeholders/img_placeholder.png');
        },
        urlImagemMb() {
            if(this.componente) {
                if(this.componente.url_img_mb) {
                    return this.componente.url_img_mb;
                }
            }
            return require('../../../assets/images/placeholders/img_placeholder.png');
        },
        corTitulo() {
            if(this.componente) {
                if(this.componente.cor_titulo) {
                    let corLista = getCor(this.componente.cor_titulo);
                    if(corLista) {
                        return corLista.var_hexa;
                    }
                }
            }
            return '#000';
        },
        corSubTitulo() {
            if(this.componente) {
                if(this.componente.cor_sub_titulo) {
                    let corLista = getCor(this.componente.cor_sub_titulo);
                    if(corLista) {
                        return corLista.var_hexa;
                    }
                }
            }
            return '#000';
        },
    },
    data: function() {
        return {
            listaCores: LISTA_CORES,
        }
    },
    methods: {
        getCorLista: function(COR) {
            return this.listaCores.find(cor => cor.chave === COR);
        },
        redireciona(pUrl) {
            if(pUrl) {
                redirecionadorExterno(this.$router, pUrl);
            }
        },
    },
}
</script>
<style scoped>

.mb-img-esq-dir-container {
    padding: 0px 5px;
    margin-top: 28px;
    margin-bottom: 28px;
    width: 100%;
}

.mb-img-esquerda-botao {
    height: auto !important;
    width: 96% !important;
    margin-bottom: 10px;
    padding-top: 14px !important;
    padding-bottom: 14px !important;
    padding-right: 8px !important;
    padding-left: 8px !important;
}
    
    .conteudo{
        list-style-type: none;  
    }

.mb-img-esq-dir-texto-centralizado {
    text-align: center;
    margin: 15px auto;
}
</style>