<template>
    <div class="header mb-header-menu">
        <nav v-bind:class="{ 'show-navbar': !showNavbar }" class="navbar navbar-light bg-light header mb-header-menu-navbar" :style="{ position: scrollPosicaoAnterior > 60 ?'fixed':'relative' }">

            <a class="navbar-brand" href="/">
                <div class="mb-header-menu-logo-container">
                    <img 
                        class="mb-header-menu-logo-img" 
                        src="https://beta-site.omni.com.br/storage/logomarcas/omni-logo.png" 
                        alt="Omni Banco &amp; Financeira"
                        loading="lazy"
                    >
                </div>
            </a>

            <button class="navbar-toggler mb-header-menu-nav-toggler" type="button" data-toggle="collapse"
                    @click="navbarHover = !navbarHover" :aria-expanded="navbarHover" aria-controls="navbarSupportedContent" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon mb-header-menu-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse px-3" v-bind:class="{show: navbarHover}" id="navbarSupportedContent" :style="setorSelecionado? corSetorSelecionado:''">

                <template v-if="setorSelecionado">

                    <div class="mb-header-menu-botao-voltar-container" @click="voltarSetor">
                        <img class="mb-header-menu-botao-voltar-img" src="@/assets/images/seta_branca.png" alt="Omni Banco & Financeira">
                    </div>

                    <ul class="navbar-nav mr-auto my-2 py-3">
                        <li class="nav-item header-menu-button text-nowrap" v-for="(setor, index) in setorSelecionado.sub_setores" :key="index">
                            <div @click="trataClickMenu(setor)">
                                <s-router-link :url="setor.url"
                                               class="nav-link header-menu-button-link-interno" id="navbarDropdownMenuLink" aria-haspopup="true" aria-expanded="false">
                                    {{setor.nome}}
                                    <span class="mb-header-menu-seta-direita" v-if="setor.sub_setores && setor.sub_setores.length > 0">
                                        <svg class="mb-header-menu-seta-direita-svg" viewBox="0 0 256 512"><path fill="white" d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"></path></svg>
                                    </span>
                                </s-router-link>
                            </div>
                        </li>
                    </ul>
                </template>

                <template v-else>

                    <div class="mb-header-menu-pesquisa">
                        <b-input-group size="sm" class="text-nowrap mb-header-menu-pesquisa-group">
                            <input v-model="textoPesquisa" class="mb-header-menu-input-pesquisa" size="lg" type="search" placeholder="Busca">
                            <button type="submit" class="mb-header-menu-btn-pesquisa" @click="pesquisarPaginas">
                                <b-icon icon="search" font-scale="1"></b-icon>
                            </button>
                        </b-input-group>
                    </div>


                    <ul class="navbar-nav mr-auto">

                        <li class="nav-item header-menu-button text-nowrap" v-for="(setor, index) in setoresMenuComCores" :key="index">
                            <div @click="trataClickMenu(setor)">
                                <s-router-link :url="setor.url"
                                               class="nav-link header-menu-button-link" id="navbarDropdownMenuLink" aria-haspopup="true" aria-expanded="false">
                                    {{setor.nome}}
                                    <span class="mb-header-menu-seta-direita" v-if="setor.sub_setores && setor.sub_setores.length > 0">
                                        <svg class="mb-header-menu-seta-direita-svg" viewBox="0 0 256 512"><path fill="var(--azul-claro-omni)" d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"></path></svg>
                                    </span>
                                </s-router-link>
                            </div>
                        </li>

                        <li class="nav-item text-nowrap">
                            <router-link class="nav-link botao-header-azul-arredondado font-weight-bold" @click="navbarHover = false" to="/pagina/area-do-cliente" id="navbarDropdownMenuLink" aria-haspopup="true" aria-expanded="false">
                                Área do Cliente
                            </router-link>
                        </li>

                        <li class="nav-item dropdown text-nowrap">
                            <a href="https://ib.omni.com.br/login" target="_blank"
                               class="nav-link botao-header-laranja-arredondado font-weight-bold"
                               id="navbarDropdownMenuLink" aria-haspopup="true" aria-expanded="false">
                                Conta Digital
                            </a>
                        </li>

                    </ul>
                </template>

            </div>

        </nav>

    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Pilha } from '@/assets/js/Pilha';
import SRouterLink from '@/components/SRouterLink';

    export default {
        name: 'MbHeaderMenu',
        components: {
            SRouterLink,
        },
        data: function() {
            return {
                idSetorSelecionado: 0,
                pilhaSetorSelecionado: Pilha(),
                setorSelecionado: null,
                corSetorSelecionado: 'background-color: var(--laranja-claro-omni);',
                textoPesquisa: '',
                showNavbar: true,
                navbarHover: false,
                scrollPosicaoAnterior: 0,
            }
        },
        computed: {
            ...mapGetters({
                setoresMenu: 'setoresMenu/setores',
            }),
            setoresMenuComCores() {
                if(this.setoresMenu) {
                    return this.setoresMenu.map(setor => ({...setor, cor: setor.ordenacao != 1? 'background-color: var(--laranja-claro-omni);': 'background-color: var(--azul-menu-omni);'}))
                } else {
                    return [];
                }
            }
        },
        methods: {
            trataClickMenu: function(pSetor) {
                if(pSetor.sub_setores && pSetor.sub_setores.length > 0) {
                    let novoSetorSelecionado = {...pSetor};
                    let novaCor = pSetor.cor == 'background-color: var(--azul-menu-omni);'? 'background-color: var(--azul-omni);': pSetor.cor;
                    novoSetorSelecionado.sub_setores = pSetor.sub_setores.map(subsetor => ({...subsetor, cor: novaCor}))
                    this.pilhaSetorSelecionado.push(novoSetorSelecionado);
                    this.setorSelecionado = this.pilhaSetorSelecionado.peek();
                    this.corSetorSelecionado = this.setorSelecionado.cor;
                } else if(pSetor.url){
                    this.fechaCalipso();
                }
            },
            trataClickContaDigital: function(pSetor) {
                if(pSetor.sub_setores && pSetor.sub_setores.length > 0) {
                    let novoSetorSelecionado = {...pSetor};
                    let novaCor = pSetor.cor == 'background-color: var(--azul-menu-omni);'? 'background-color: var(--azul-omni);': pSetor.cor;
                    novoSetorSelecionado.sub_setores = pSetor.sub_setores.map(subsetor => ({...subsetor, cor: novaCor}))
                    this.pilhaSetorSelecionado.push(novoSetorSelecionado);
                    this.setorSelecionado = this.pilhaSetorSelecionado.peek();
                    this.corSetorSelecionado = this.setorSelecionado.cor;
                } else {
                    this.fechaCalipso();
                }
            },
            voltarSetor: function() {
                this.pilhaSetorSelecionado.pop();
                this.setorSelecionado = this.pilhaSetorSelecionado.peek();
                this.corSetorSelecionado = this.setorSelecionado? this.setorSelecionado.cor: 'background-color: var(--laranja-claro-omni);';
            },
            fechaCalipso: function() {
                let botaoCalipso = document.querySelector('.navbar-toggler')
                botaoCalipso.click();
                this.pilhaSetorSelecionado = Pilha();
                this.setorSelecionado = null;
                this.corSetorSelecionado = 'background-color: var(--laranja-claro-omni);';
            },
            pesquisarPaginas: function() {
                if(!this.textoPesquisa) {
                    return;
                }
                this.$router.push({
                    name: 'BuscaPaginasPage',
                    query: { texto: this.textoPesquisa }
                }).catch(() => { 
                    console.log('catch do push') 
                })
            },
            onScroll: function () {
                const scrollPosicaoAtual = window.pageYOffset || document.documentElement.scrollTop

                if (scrollPosicaoAtual < 0) {
                    return
                }
                if (Math.abs(scrollPosicaoAtual - this.scrollPosicaoAnterior) < 60) {
                    return
                }

                this.showNavbar = scrollPosicaoAtual < this.scrollPosicaoAnterior;
                this.scrollPosicaoAnterior = scrollPosicaoAtual;

                if (this.showNavbar && this.scrollPosicaoAnterior > 0) {
                    this.navbarHover = false;
                    setTimeout(this.hideNavbar, 4000)
                }
            },
            hideNavbar: function () {
                if (this.scrollPosicaoAnterior > 60 && !this.navbarHover) {
                    this.showNavbar = false
                }
            },
        },
        mounted() {
            window.addEventListener('scroll', this.onScroll)
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.onScroll)
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .mb-header-menu {
        z-index: 100;
        font-family: MuseoSans;
        font-weight: 300;
    }

    .mb-header-menu-navbar {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        transition: 0.5s all ease-out;
        transform: translate3d(0, 0, 0);
        transition: 0.5s all ease-out;
        position: fixed;
    }

    .show-navbar {
        transform: translate3d(0, -100%, 0);
        transition: 0.5s all ease-out;
    }

.mb-header-menu-logo-container {
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 8px;
}

.mb-header-menu-logo-img {
    margin: 0px;
    height: 55px;
    width: auto;
}

.mb-header-menu-nav-toggler, .mb-header-menu-nav-toggler:focus {
    border: none;
    outline: none;
}

.mb-header-menu-toggler-icon {
    margin-right: 5px;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' heiht='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(20,125,205, 1)' stroke-linecap='square' stroke-miterlimit='10' stroke-width='3' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}

.mb-header-menu-pesquisa {
    margin: 15px auto;
    padding-left: 20px;
    padding-right: 7px;
    height: 60px;
    width: 100%;
    background-color: var(--cinza-areia-omni);
    border-radius: 10px;
    opacity: 1.2;
    font-family: MuseoSans;
    font-weight: 500;
    font-size: 20px;
    color: var(--cinza-escuro-omni);
}

.mb-header-menu-input-pesquisa {
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-radius: 0px;
    color: var(--cinza-escuro-omni);
    font-size: 20px;
    font-weight: 500;
    height: 60px;
    width: calc(100% - 35px);
}
    .mb-header-menu-input-pesquisa::-webkit-search-cancel-button{
        display: none;   
    }

.mb-header-menu-btn-pesquisa, .mb-header-menu-btn-pesquisa:focus {
    color: #646464;
    background-color: transparent;
    border-color: transparent;
    outline: none;
    max-width: 30px;
}


.header-menu-button {
    font-family: MuseoSans;
    font-size: 20px;
    font-weight: 500;
    padding-left: 20px;
    padding-right: 20px;
    text-align: right;
}
    .header-menu-button:focus {
        opacity: 2;
    }

    .header-menu-button-link {
        color: var(--azul-claro-omni) !important;
        width: auto;
        float: right;
    }

    .header-menu-button-link-interno {
        color: #fff !important;
        width: auto;
        float: right;
    }

.navbar .dropdown-item.active,
.navbar .dropdown-item:active {
  text-decoration: none;
  background-color: inherit;
}

.navbar .dropdown-item:focus,
.navbar .dropdown-item:hover {
  text-decoration: none;
  background-color: #f8f9fa;
}


.botao-header-azul-arredondado {
    border-radius: 10px;
    padding: 9px 15px 9px 15px !important;
    background-color: var(--azul-claro-omni);
    color: #f8f9fa !important;
}

.botao-header-laranja-arredondado {
    border-radius: 10px;
    padding: 9px 15px 9px 15px !important;
    background-color: var(--laranja-claro-omni);
    color: #f8f9fa !important;
}

.botao-header-azul-arredondado,
.botao-header-laranja-arredondado {
    width: 55%;
    margin-top: 10px;
    height: 3.7rem;
    text-align: center;
    padding-top: 1.1rem !important;
    float: right;
}

.botao-header-azul-arredondado:hover,
.botao-header-laranja-arredondado:hover {
    opacity: 0.7;
}

.mb-header-menu-botao-voltar-container {
    width: 15px;
    height: auto;
    margin-top: 35px; 
    margin-left: 10px;
    position: absolute; 
}
.mb-header-menu-botao-voltar-img {
    width: 100%;
    height: 100%;
}

.mb-header-menu-seta-direita {
    position: absolute;
    height: 20px;
    width: 20px;
}

.mb-header-menu-seta-direita-svg {
    transform: rotate(270deg);
    height: 20px;
    width: 20px;
}


</style>




