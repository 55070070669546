<template>
    <div class="component-container" :style="{ 'background-color': corFundo }">
        <div class="container-fluid w-100 pb-5" v-bind:style="{ 'background-color': corFundo }" v-if="componente">

            <div class="row" style="min-height: 150px;">
                <div class="w-100">
                    <b-img-lazy 
                        fluid-grow
                        :src="urlImagem" 
                        alt="Imagem"
                    >
                    </b-img-lazy>
                </div>
            </div>

            <div class="row my-2 pt-4" v-if="componente.titulo">
                <div class="col-12">
                    <h3 class="mb-img-esquerda-titulo" :style="{ 'color': corTitulo }">{{ componente.titulo }}</h3>
                </div>
            </div>

            <div class="row my-2" v-if="componente.sub_titulo">
                <div class="col-12">
                    <h5 class="mb-img-esquerda-sub-titulo" :style="{ 'color': corSubTitulo }">{{ componente.sub_titulo }}</h5>
                </div>
            </div>

            <div class="row my-3 py-2">
                <div class="col-10 mx-auto">
                    <span v-html="componente.texto" :style="{ 'color': corTexto }" class="mx-auto conteudo-html-components mb-img-esquerda-container-texto"></span>
                </div>
            </div>

            <div class="row my-2" v-if="temBotao1">
                <div class="col-12">
                    <botao-m @click="redireciona(componente.url_botao_1)" :style="{ 'background-color': corBotao1 }" class="mb-img-esquerda-botao"> {{textoBotao1}} </botao-m>
                </div>
            </div>

            <div class="row my-2" v-if="temBotao2">
                <div class="col-12">
                    <botao-m @click="redireciona(componente.url_botao_2)" :style="{ 'background-color': corBotao2 }" class="mb-img-esquerda-botao"> {{textoBotao2}} </botao-m>
                </div>
            </div>
            
        </div>
    </div>
</template>
<script>
import { temasFactory, getCor } from '@/assets/js/SharkUtils';
import { LISTA_CORES } from '@/assets/js/dicionarioPaginas';
import { redirecionadorExterno } from '@/assets/js/SharkUtils';
import BotaoM from '@/components/botoes/BotaoM';

export default {
    name: 'MbImgEsquerdaHome',
    components: {
        BotaoM,
    },
    props: {
        id_comp: Number,
        componente: Object,
    },
    computed: {
        tema() {
            if(this.componente) {
                return temasFactory(this.componente.tema);
            }
            return null;
        },
        corFundo() {
            if(this.componente) {
                if(this.componente.tema) {
                    let corLista = getCor(this.componente.tema);
                    if(corLista) {
                        return corLista.var_hexa;
                    }
                }
            }
            return '#fff';
        },
        corTexto() {
            return '#000';
        },
        temBotao1() {
            if(this.componente) {
                if(this.componente.txt_botao_1) {
                    return this.componente.txt_botao_1.length > 0 ? true: false;
                }
            }
            return false;
        },
        textoBotao1() {
            if(this.componente) {
                if(this.componente.txt_botao_1) {
                    return this.componente.txt_botao_1
                }
            }
            return 'Clique aqui!';
        },
        corBotao1() {
            if(this.componente) {
                if(this.componente.cor_botao_1) {
                    let corLista = getCor(this.componente.cor_botao_1);
                    if(corLista) {
                        return corLista.var_hexa;
                    }
                }
            }
            return 'var(--laranja-omni)';
        },
        temBotao2() {
            if(this.componente) {
                if(this.componente.txt_botao_2) {
                    return this.componente.txt_botao_2.length > 0 ? true: false;
                }
            }
            return false;
        },
        textoBotao2() {
            if(this.componente) {
                if(this.componente.txt_botao_2) {
                    return this.componente.txt_botao_2
                }
            }
            return 'Clique aqui!';
        },
        corBotao2() {
            if(this.componente) {
                if(this.componente.cor_botao_2) {
                    let corLista = getCor(this.componente.cor_botao_2);
                    if(corLista) {
                        return corLista.var_hexa;
                    }
                }
            }
            return 'var(--laranja-omni)';
        },
        urlImagem() {
            if(this.componente) {
                if(this.componente.url_img) {
                    return this.componente.url_img;
                }
            }
            return require('../../../assets/images/placeholders/img_placeholder.png');
        },
        corTitulo() {
            if(this.componente) {
                if(this.componente.cor_titulo) {
                    let corLista = getCor(this.componente.cor_titulo);
                    if(corLista) {
                        return corLista.var_hexa;
                    }
                }
            }
            return '#000';
        },
        corSubTitulo() {
            if(this.componente) {
                if(this.componente.cor_sub_titulo) {
                    let corLista = getCor(this.componente.cor_sub_titulo);
                    if(corLista) {
                        return corLista.var_hexa;
                    }
                }
            }
            return '#000';
        },
    },
    data: function() {
        return {
            listaCores: LISTA_CORES,
        }
    },
    methods: {
        getCorLista: function(COR) {
            return this.listaCores.find(cor => cor.chave === COR);
        },
        redireciona(pUrl) {
            if(pUrl) {
                redirecionadorExterno(this.$router, pUrl);
            }
        },
    },
}
</script>

<style scoped>

.component-container {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    width: 100%;
    padding-left: 0;
    min-height: 600px;
    margin-top: 28px;
    margin-bottom: 28px;
}
    
.mb-img-esquerda-titulo, 
.mb-img-esquerda-sub-titulo {
    text-align: center;
}

.mb-img-esquerda-container-texto {
    width: 100%;
}

.mb-img-esquerda-botao {
    height: 4em !important;
    width: 90% !important;
    max-width: 300px;
    margin-bottom: 10px;
}

.mb-img-esquerda-imagem {
    width: 100%;
    height: auto;
}

.mb-img-esquerda-titulo {
    font-weight: 900;
}

.mb-img-esquerda-sub-titulo {
    font-weight: 900;
}

</style>