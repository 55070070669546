<template>
  <div class="mb-lista-icones-container" :id="`mb-lista-icones-${id_comp}`" :key="`lista-icones-${id_comp}`" v-bind:style="{ 'background-color': corFundo }">
    <div class="container-fluid">
        <div class="row mx-auto my-4 px-2" v-if="tituloLista">
            <h2 v-bind:style="{ color: corTitulo }" class="mx-auto lista-icones-titulo-lista text-center"> {{ tituloLista }}</h2>
        </div>
        
        <div :id="`carousel-lista-icones-${id_comp}`" class="carousel slide mb-3 mt-4" data-ride="carousel" data-interval=false>

            <div class="carousel-inner">
                <div class="carousel-item" v-for="(item, index) in itens" :key="index" :class="{active:index == 0}" >

                    <mb-lista-icones-bloco-icone 
                    :id_item="item.id"
                    :url_icone="item.url_icone"
                    :titulo="item.titulo"
                    :sub_titulo="item.sub_titulo"
                    :texto="item.texto"
                    :cor_titulo="item.cor_titulo"
                    :cor_sub_titulo="item.cor_sub_titulo"
                    :cor_texto="'#fff'"
                    :alinhamento="tipo"
                    :link="item.url_redir"
                    @carregou="trataAltura"
                    :alturaMinima="maiorAlturaAproximada"
                    />

                </div>
            </div>
            
            <ol class="carousel-indicators">
                <li v-for="(item, index) in itens" :key="index" :class="{active:index == 0}" :data-target="`#carousel-lista-icones-${id_comp}`" :data-slide-to="index">
                  <div class="mb-lista-icones-dots-class">
                  </div>
                </li>
            </ol>
        </div>

    </div>
  </div>
</template>

<script>

import MbListaIconesBlocoIcone from '@/mobile/components/page-components/MbListaIconesBlocoIcone.vue';
import { getCor } from '@/assets/js/SharkUtils';
import $ from 'jquery'

export default {
  name: 'MbListaIcones',
  components: {
      MbListaIconesBlocoIcone
  },
  props: {
    id_comp: Number,
    componente: Object,
  },
  methods: {
    trataAltura(pAltura) {
      return pAltura;
    },
    calculaAlturaMinima() {
      if(!this.$el) {
        return
      }
      // pegando a altura maxima
      let itensCarrossel = this.$el.querySelectorAll('.carousel-item');
      let alturaComputadaDivTexto = ''
      if(!itensCarrossel || itensCarrossel.length  == 0) {
        return
      }
      // percorre todos os itens do carrosel calculando sua altura ativando e desativando sua visibilidade
      itensCarrossel.forEach( (item) => {
        if(!item) {
          return
        }
        item.classList.add("active")
        alturaComputadaDivTexto = ''
        if(itensCarrossel) {
          alturaComputadaDivTexto =  getComputedStyle(item).getPropertyValue("height")
          alturaComputadaDivTexto = alturaComputadaDivTexto.replace(/[^0-9.,]/g, '')
        }
        if(alturaComputadaDivTexto && Number(alturaComputadaDivTexto) > this.maiorAlturaAproximada) {
          this.maiorAlturaAproximada = Number(alturaComputadaDivTexto) + 35
        }
        item.classList.remove("active")
      });
      // Depois deixa o primeiro item visivel novamente
      let i = 0
      while(i < 15) {
        if(itensCarrossel.length && itensCarrossel[i]) {
          itensCarrossel[i].classList.add("active")
          i=15
        }
        i++
      }

    },
    verificaItensAtivos() {
      if(!this.$el) {
        return
      }
      if(!itensCarrossel || itensCarrossel.length == 0) {
        return
      }
      let itensCarrossel = this.$el.querySelectorAll('.carousel-item')
      
      let temItemAtivo = false
      itensCarrossel.forEach((item)=> {
        if(!item) {
          return
        }
        if(!temItemAtivo) {
          temItemAtivo = item.classList.contains("active")
        }
      })
      
      let i = 0
      while(i < 15) {
        if(itensCarrossel.length && itensCarrossel[i]) {
          itensCarrossel[i].classList.add("active")
          i=15
        }
        i++
      }
      
    },
    ativaSwipe() {
      let selectorCarossel = `#carousel-lista-icones-${this.id_comp}`
      // Adicionando eventos para swipe no mobile
      // Mobile swipe if more than 5 pixels moved
      $(selectorCarossel).on("touchstart", function (event) {
          let xClick = event.originalEvent.touches[0].pageX;
          $(this).one("touchmove", function (event) {
              var xMove = event.originalEvent.touches[0].pageX;
              if (Math.floor(xClick - xMove) > 5) {
                  $(this).carousel('next');
              }
              else if (Math.floor(xClick - xMove) < -5) {
                  $(this).carousel('prev');
              }
          });
          $(selectorCarossel).on("touchend", function () {
              $(this).off("touchmove");
          });
      });

      // Mouse swipe when mouse is dragged to left/right
      let xClick;
      let mouseDown;

      $(selectorCarossel).on("mousedown", function (event) {
          xClick = event.pageX;
          mouseDown = true;
      });

      $(selectorCarossel).on("mousemove", function (event) {
          if (mouseDown) {
              let xMove = event.pageX;
              if (xClick > xMove) {
                  $(this).carousel('next');
              }
              else if (xClick < xMove) {
                  $(this).carousel('prev');
              }
          }
      });

      $(selectorCarossel).on("mouseup", function () {
          mouseDown = false;
      });
    },
  },
  watch: {
      // whenever question changes, this function will run
      id_comp: function () {
          this.maiorAlturaAproximada = 110
          setTimeout(() => {
            this.calculaAlturaMinima();
            this.ativaSwipe();
          }, 1200)
      }
  },
  computed: {
      itens() {
        if(this.componente) {
            return this.componente.itens? this.componente.itens: [];
        } else {
            return [];
        }
      },
      corFundo() {
        if(this.componente) {
          return getCor(this.componente.tema).var_hexa;
        }
        return '#000';
      },
      corTitulo() {
        if(this.componente) {
          return getCor(this.componente.cor_titulo).var_hexa;
        }
        return '#fff';
      },
      tituloLista() {
        if(this.componente) {
          if(this.componente.titulo) {
            return this.componente.titulo;
          }
        }
        return '';
      },
      tipo() {
        if(this.componente){
          if(this.componente.tipo === 'LISTA_ICONES_ESQ'){
            return 'ESQUERDA';
          }
          return '';
        }
        return '';
      }
  },
  data: function() {
    return {
      maiorAlturaAproximada: 110,
      interval: null,
    }
  },
  created() {
    $('.carousel').carousel();
  },
  mounted() {
    this.calculaAlturaMinima();
    this.ativaSwipe();
  },
  updated() {
    if(!this.interval) {
      this.interval = setInterval(()=> {
        this.verificaItensAtivos()
      }, 5000)
    }

    if(this.interval) {
      clearInterval(this.interval)
      this.interval = setInterval(()=> {
        this.verificaItensAtivos()
      }, 5000)
    }
  },
  beforeDestroy() {
    if(this.interval) {
      clearInterval(this.interval)
    }
  }
}
</script>

<style scoped>

.mb-lista-icones-container {
  width: 100% !important;
  max-width: 100vw !important;
}

.carousel-indicators li {
    opacity:unset !important;
    border-radius: 50%;
    width: 20px !important;
    height: 20px !important;
    margin-left: 5px !important;
    margin-right: 5px !important;
}

.mb-lista-icones-dots-class {
    background-color: var(--cinza-omni) !important;
    height: 6px;
    border-radius: 3px;
    width: 25px;
}
.carousel-indicators li {
  background-color: transparent;
}
.carousel-indicators li.active .mb-lista-icones-dots-class {
    background-color: var(--azul-claro-omni) !important;
}


</style>

