 <template>
  <div class="mb-lista-icones-bloco-icone-container" :key="id_item" :style="`min-height: ${this.alturaMinima}px;`" >
    <div class="container-fluid mb-lista-icones-bloco-icone-hover" 
        @click="iconRedirect(linkIcon)" 
        :style="this.link? 'cursor: pointer;':''"
    >
        
          <div class="row py-2" v-if="urlIcone">
              <div class="col-10 mx-auto">
                  <div class="mb-lista-icones-bloco-icone-img-container">
                      <img class="mb-lista-icones-bloco-icone-img" :src="urlIcone" alt="icone"/>
                  </div>
              </div>
          </div>

          <div class="row px-3 pt-3" v-if="titulo">
              <h5 class="mx-auto mb-lista-icones-bloco-icone-titulo" v-bind:style="{ color: corTitulo }"> 
                  {{ titulo }}
              </h5>
          </div>
          
          <div class="row px-3 pt-1" v-if="sub_titulo">
              <h5 class="mx-auto mb-lista-icones-bloco-icone-titulo" v-bind:style="{ color: corSubTitulo }"> 
                  {{ sub_titulo }}
              </h5>
          </div>

          <div class="row px-3 my-2">
              <div class="mx-auto mb-lista-icones-bloco-icone-texto conteudo-html-components" v-html="texto" :style="{ color: corTexto }">
              </div>
          </div>

    </div>
  </div>
</template>

<script>
import { getCor } from '@/assets/js/SharkUtils';

export default {
  name: 'MbListaIconesBlocoIcone',
  props: {
    id_item: Number,
    url_icone: String,
    titulo: String,
    sub_titulo: String,
    texto: String,
    cor_titulo: String,
    cor_sub_titulo: String,
    cor_texto: String,
    link: String,
    alinhamento: String,
    alturaMinima: Number,
  },
  methods: {
    iconRedirect(link){
      if(link) {
        window.open(link);
      }
    },
  },
  computed: {
    urlIcone() {
        if(this.url_icone) {
          return this.url_icone;
        }
        return '';
    },
    linkIcon() {
        if(this.link) {
          return this.link;
        }
        return '';
    },
    corTitulo() {
      if(this.cor_titulo) {
        return getCor(this.cor_titulo).var_hexa;
      }
      return '#000';
    },
    corSubTitulo() {
      if(this.cor_sub_titulo) {
        return getCor(this.cor_sub_titulo).var_hexa;
      }
      return '#000';
    },
    corTexto() {
      if(this.cor_texto) {
        return getCor(this.cor_texto).var_hexa;
      }
      return '#000';
    }
  },
  data: function() {
    return {
    }
  },
  mounted() {
  },
  updated() {
  },
}
</script>

<style scoped>

.mb-lista-icones-bloco-icone-container {
    width: 100%;
    max-width: 100vw;
    overflow-x: hidden;
}

.linha-produtos-flex-container {
  flex-wrap: wrap;
  width: 100%;
}

.mb-lista-icones-bloco-icone-img-container {
	display: flex;
	flex-direction: column-reverse;
  max-width: 100%;
  min-width: 50px;
  margin: 0 auto;
  min-height: 110px;
  overflow: hidden;
  position: relative;
}

  .mb-lista-icones-bloco-icone-img {
    max-width: 75%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

.mb-lista-icones-bloco-icone-texto {
  word-break: normal;
  overflow-wrap: normal !important;
  text-align: center;
  font-weight: bold;
}

.mb-lista-icones-bloco-icone-titulo {
  text-align: center;
  font-weight: 900;
}

.lista-icones-bloco-icone-hover:hover {
  opacity: 0.7;
}

.mb-lista-icones-bloco-icone-texto {
  max-width: 98vw;
  overflow-x: hidden;
}

</style>