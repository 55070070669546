import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import * as sharkUtils from '@/assets/js/SharkUtils'

import LoginPageAdmin from '@/views/admin/LoginAdmPage.vue'

// Mobile
import MbHomePage from './views/MbHomePage.vue'
import MbRenderizadorPage from './views/MbRenderizadorPage.vue'

const MbSejaAgentePage = () => import(/* webpackChunkName: "outras-paginas" */'../mobile/views/agentes/MbSejaAgentePage.vue')
const MbResultadoAgentesPage = () => import(/* webpackChunkName: "outras-paginas" */'../mobile/views/agentes/MbResultadoAgentesPage.vue')
const MbEncontreAgentePage = () => import(/* webpackChunkName: "outras-paginas" */'../mobile/views/agentes/MbEncontreAgentePage.vue')
const MbBlogPage = () => import(/* webpackChunkName: "outras-paginas" */'./views/blog/MbBlogPage.vue')
const MbBlogPostPage = () => import(/* webpackChunkName: "outras-paginas" */'./views/blog/MbBlogPostPage.vue')
const MbAgenteOmniPage = () => import(/* webpackChunkName: "outras-paginas" */'./views/agentes/MbAgenteOmniPage.vue')
// Responsivos
const BuscaPaginasPage = () => import(/* webpackChunkName: "outras-paginas" */'../views/BuscaPaginasPage.vue')


Vue.use(VueRouter)

const routes = [
  {
    path: '/acesso',
    name: 'LoginPage',
    component: LoginPageAdmin,
    beforeEnter: (to, from, next) => {
        const token = (store.getters['authAdmin/admAuth'])
        if (token) {
            return next({
                name: 'HomePage'
            })
        } else {
            next();
        }
    }
  },
  {
    path: '/',
    name: 'HomePage',
    component: MbHomePage,
    meta: { requiresAuth: true },
  },
  {
    path: '/correspondentes',
    name: 'EncontreAgentePage',
    component: MbEncontreAgentePage,
    meta: { title: 'Encontre um correspondente', requiresAuth: true },
  },
  {
    path: '/correspondentes/:uri',
    name: 'AgenteOmniPage',
    component: MbAgenteOmniPage,
    meta: { title: 'Correspondente Omni', requiresAuth: true },
  },
  {
    path: '/buscar-correspondentes',
    name: 'ResultadoAgentesPage',
    component: MbResultadoAgentesPage,
    props: true,
    meta: { title: 'Correspondentes Omni', requiresAuth: true },
  },
  {
    path: '/seja-correspondente',
    name: 'SejaAgentePage',
    component: MbSejaAgentePage,
    meta: { title: 'Seja um Correspondente Omni', requiresAuth: true },
  },
  {
    path: '/buscar',
    name: 'BuscaPaginasPage',
    component: BuscaPaginasPage,
    props: true,
    meta: { title: 'Busca', requiresAuth: true },
  },
  {
    path: '/pagina/:url',
    component: MbRenderizadorPage,
    name: 'RenderizadorPage',
    props: true,
    meta: { tituloDinamico: true, requiresAuth: true },
  },
  {
    path: '/blog',
    name: 'BlogPage',
    component: MbBlogPage,
    meta: { title: 'Blog da Omni', requiresAuth: true },
  },
  {
    path: '/blog/:id',
    name: 'BlogPostPage',
    component: MbBlogPostPage,
    props: true,
    meta: { title: 'Blog da Omni', requiresAuth: true },
  },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
        return savedPosition
    }
    
    if (to.hash && document.querySelector(to.hash)) {
        return setTimeout(() => { 
        document.querySelector(to.hash).scrollIntoView({ behavior: 'smooth' })
        }, 300)
    }
    if (to.hash) {
        return setTimeout(() => { 
        if(document.querySelector(to.hash)) {
            return document.querySelector(to.hash).scrollIntoView({ behavior: 'smooth' })
        }
        setTimeout(() => document.querySelector(to.hash)? document.querySelector(to.hash).scrollIntoView({ behavior: 'smooth' }):null, 1500)
      }, 1000)
    }

    return { x: 0, y: 0 }
    }
})

router.beforeEach(async (to, from, next) => {
    let adminLogado = (store.getters['authAdmin/admUser'])
    if (to.meta.requiresAuth && !adminLogado) {
        next({
            name: 'LoginPage',
            query: { acessoRestrito: false }
        })
    } else {
        next();
    }

    let token;
    try {
        // A cada navega��o, verifica validade da autentica��o
        await store.dispatch('authAdmin/attempt', adminLogado)
        token = (store.getters['authAdmin/admAuth'])
    } catch (e) {
        console.log('beforeEachError', e)
        alert('Erro de autentica��o')
        return next({
            name: 'LoginPage',
            query: { acessoRestrito: false }
        })
    }

    if (!token) {
        alert('N�o autorizado')
        return next({
            name: 'LoginPage',
            query: { acessoRestrito: false }
        })
    }

    return next();
})

router.afterEach((to) => {
    if (to.hash === '#chat') {
        sharkUtils.abrirChat();
        router.push({ hash: '' }).catch(() => { });
    }

    if(to.meta && to.meta.tituloDinamico) {
      return
    }

    Vue.nextTick(() => {
      if(to.meta && to.meta.title) {
          document.title = `Omni Banco & Financeira - ${to.meta.title}`
          return
      }

      document.title = `Omni Banco & Financeira` 

  })
})

export default router
