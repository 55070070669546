<template>
    <div class="mb-faixa-texto-imagem-container container-fluid text-center" >

        <div class="row">
            <div class="col-12">

                <b-img class="mx-auto px-auto w-75" :src="urlImagem"></b-img>
                
            </div>
        </div>

        <div class="row" :style="{ 'background-color':corFundo }">
            <div class="col-12 py-3">

                <div class="my-4 mx-auto w-100" :style="{ 'color': corTexto }" ><span v-html="componente.texto"></span></div>

            </div>
        </div>

    </div>
</template>

<script>
import { getCor } from '@/assets/js/SharkUtils';

export default {
    name: 'MbFaixaTextoImagem',
    data: function(){
        return {}
    },
    props: {
        id_comp: Number,
        componente: Object,
    },
    computed:{
        corFundo() {
            if(this.componente) {
                if(this.componente.tema) {
                    let corLista = getCor(this.componente.tema);
                    if(corLista) {
                        return corLista.var_hexa;
                    }
                }
            }
            return '#fff';
        },
        corTexto() {
            if(this.componente) {
                if(this.componente.cor_texto) {
                    let corLista = getCor(this.componente.cor_texto);
                    if(corLista) {
                        return corLista.var_hexa;
                    }
                }
            }
            return '#fff';
        },
        urlImagem() {
            if(this.componente) {
                if(this.componente.url_img) {
                    return this.componente.url_img;
                }
            }
            return '';
        },
    }
}
</script>

<style scoped>
.mb-faixa-texto-imagem-container {
    width: 100%;
    max-width: 100vw;
    overflow-x: hidden;
    margin: 0;
}

</style>