<template>
   <div class="container mb-footer-caixa-expandivel-container" >

        <div class="row">
            <p class="mb-footer-caixa-expandivel-titulo">{{titulo}} <span @click="toggle" class="float-right"> {{ aberta? '-': '+' }} </span> </p>
        </div>
        
        <div class="row pl-2" v-show="aberta">
            <slot>

            </slot>
        </div>
       
   </div>
</template>

<script>

export default {
    name: 'MbFooterCaixaExpandivel',
    components: {
    },
    props: {
        titulo: String,
    },
    data: function() {
        return {
            aberta: false,
        }
    },
    methods: {
        toggle() {
            this.aberta = !this.aberta;
        },
    },
    computed: {
    },
    mounted() {
    }
    
}

</script>

<style scoped>
    .mb-footer-caixa-expandivel-container {
        padding-bottom: 5px;
    }

    .mb-footer-caixa-expandivel-titulo {
        color: var(--chumbo-omni);
        font-family: MuseoSans;
        font-size: 13px;
        font-weight: bold;
        width: 100%;
        margin-bottom: 4px;
    }

</style>