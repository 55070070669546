<template>
    <div class="mb-ultimas-noticias-container">
        <div class="container-fluid">

            <div class="row">
                <div class="col-md-11 mx-auto">
                    <div class="container-fluid">
                    
                        <div class="row">
                            <div class="col-12 text-center mx-auto">
                                <h2 class="mb-ultimas-noticias-ultimas">Últimas notícias</h2>
                            </div>
                        </div>

                        <div class="row mb-ultimas-noticias-swipe-row">

                            <div class="col-12 mx-auto mb-ultimas-noticias-caixa-resultados" v-if="postSelecionado">
                                <router-link :to="`/blog/${postSelecionado.id}`">
                                    <div class="mb-ultimas-noticias-relacionadas-img-container">
                                        <img :src="postSelecionado.url_imagem" class="mb-ultimas-noticias-relacionadas-img"  alt="imagem publicacao"/>
                                    </div>
                                    <h5 class="mx-auto mb-ultimas-noticias-titulo-pagina"> {{ postSelecionado.titulo }} </h5>
                                </router-link>
                                <div class="mb-ultimas-noticias-paragrafo-info-container">
                                    <p class="w-75 mb-ultimas-noticias-paragrafo-info"> Ano {{ postSelecionado.ano }} - Edição {{ postSelecionado.edicao }} </p>
                                    <p class="w-75 mb-ultimas-noticias-paragrafo-info"> por {{ postSelecionado.autor }} </p>
                                </div>
                            </div>

                        </div>

                        <div class="row">
                            <div class="col-10 mx-auto">
                                <div class="mb-ultimas-noticias-container-botoes mx-auto my-3">
                                    <span v-for="(post, index) in ultimosPosts" :key="index" class="mx-1 mb-ultimas-noticias-container-botao">
                                        <button 
                                            :style="index==indiceSelecionado? 'background-color:var(--azul-claro-omni)':''" 
                                            @click="trocaIndice(index)"
                                            class="mb-ultimas-noticias-botao-redondo"
                                        >
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-11 mx-auto text-center">
                                <router-link :to="'/blog'" class="mb-ultimas-noticias-mais-omni">
                                    <span class="mb-ultimas-noticias-mais-omni-span"> Mais Omni Blog </span> 
                                    <img src="@/assets/images/seta_laranja.png" class="pl-1 mx-auto"/>
                                </router-link>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            

        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { detectSwipe } from '@/assets/js/SharkUtils';


export default {
    name: 'MbUltimasNoticias',
    components: {
    },
    props: {
        id_comp: Number,
        componente: Object,
    },
    data: function () {
        return {
            indiceSelecionado: 0,
            inicializouSwipe: false,
        }
    },
    methods: {
        ...mapActions({
        getPosts: 'posts/getPosts'
        }),
        trocaIndice: function(pIndice) {
            this.indiceSelecionado = pIndice;
        },
        next: function() {
            if(this.indiceSelecionado < 2) {
                this.indiceSelecionado++;
            }
        },
        prev: function() {
            if(this.indiceSelecionado > 0) {
                this.indiceSelecionado--;
            }
        },
    },
    computed: {
        ...mapGetters({
            lista_posts: 'posts/posts',
        }),
        ultimosPosts() {
            if(!this.lista_posts || this.lista_posts.length == 0) {
                return [];
            }
            return this.lista_posts.slice(0, 3);
        },
        postSelecionado() {
            if(!this.ultimosPosts || this.ultimosPosts.length == 0) {
                return null;
            }
            return this.ultimosPosts[this.indiceSelecionado];
        },
    },
    created() {
        this.getPosts();
    },
    mounted() {
        if(!this.inicializouSwipe) {
            detectSwipe('.mb-ultimas-noticias-swipe-row', (el, dir) => {
                this.inicializouSwipe = true;
                if(dir == 'right') {
                    this.prev()
                }
                if(dir == 'left') {
                    this.next()
                }
            })
        }
    },
}

</script>

<style scoped>
.mb-ultimas-noticias-container {
    width: 100%;
    max-width: 100%;
    padding: 2px 5px;
    margin-top: 28px;
    margin-bottom: 28px;
    min-height: 400px;
}

.mb-ultimas-noticias-ultimas {
    color: var(--azul-claro-omni);
    font-weight: 700;
}

.mb-ultimas-noticias-mais-omni {
    color: var(--laranja-claro-omni);
    font-size: 18px;
}
    .mb-ultimas-noticias-mais-omni:hover {
        opacity: 0.7;
    }

.mb-ultimas-noticias-mais-omni-span {
    font-weight: 500;
    font-size: 20px;
}

.mb-ultimas-noticias-caixa-resultados {
    margin: 10px auto;
    width: 100%;
    max-width: 100%;
}

.mb-ultimas-noticias-relacionadas-img-container {
    max-width: 380px !important;
    width: 100% !important;
    height: 120px;
    margin: auto;
}
    .mb-ultimas-noticias-relacionadas-img-container:hover {
        opacity: 0.7;
    }

.mb-ultimas-noticias-relacionadas-img {
    position: relative;
    max-height: 100%;
    display: block;
    width: 100% !important;
}

.mb-ultimas-noticias-titulo-pagina {
  width: 75%;
  text-align: center;
  padding-top: 25px;
  color: var(--azul-claro-omni);
  font-family: MuseoSans;
  font-weight: 900;
}

.mb-ultimas-noticias-titulo-pagina:hover {
    opacity: 0.7;
    text-decoration: underline;
    cursor: pointer;
}

.mb-ultimas-noticias-paragrafo-info-container {
    min-height: 4em;
}

.mb-ultimas-noticias-paragrafo-info {
    text-align: center;
    font-family: MuseoSans;
    font-weight: 500;
    padding-bottom: 0px;
    margin: 0 auto;
    font-size: 14px;
}

.mb-ultimas-noticias-container-botoes {
    text-align: center;
    width: 100%;
}

.mb-ultimas-noticias-container-botao {
    width: 19px;
    height: 19px;
}

.mb-ultimas-noticias-botao-redondo {
    border:none;
    outline:none;
    background-color: var(--cinza-omni);
    height: 6px;
    border-radius: 3px;
    width: 25px;
}

</style>

