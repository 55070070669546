import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store'
import { 
  LayoutPlugin, ModalPlugin, CardPlugin, BIcon, BIconSearch, BIconFiles, BIconTrashFill, BIconPencilSquare, FormPlugin, ImagePlugin, InputGroupPlugin  
} from 'bootstrap-vue'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import vueScrollto from 'vue-scrollto'
import * as VueGoogleMaps from 'vue2-google-maps';

// This imports all the layout components such as <b-container>, <b-row>, <b-col>:
Vue.use(LayoutPlugin)
// This imports <b-modal> as well as the v-b-modal directive as a plugin:
Vue.use(ModalPlugin)
// This imports <b-card> along with all the <b-card-*> sub-components as a plugin:
Vue.use(CardPlugin)
Vue.use(FormPlugin)
Vue.use(ImagePlugin)
Vue.use(InputGroupPlugin)

// do not place any value - it will give you the error that the component is already loaded
require('@/config/httpConfig');
require('@/assets/css/variaveis-root-omni.css');

Vue.config.productionTip = false

// global
Vue.use(vueScrollto)
// Vue.use(BootstrapVue)
Vue.component('BIcon', BIcon)
Vue.component('BIconSearch', BIconSearch)
Vue.component('BIconFiles', BIconFiles)
Vue.component('BIconTrashFill', BIconTrashFill)
Vue.component('BIconPencilSquare', BIconPencilSquare)
Vue.use(VueGoogleMaps) 

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


