<template>
  <div class="mb-form-agentes-container">
    <div class="container-fluid">

        <div class="row">
            <div class="col-8 mx-auto">
                <div class="mb-form-agentes-img-container">
                    <img src="@/assets/images/agentes/agente_omni_busca.png" class="mb-form-agentes-img"/>
                </div>
            </div>
        </div>

        <div class="row">

            <div class="col-12 coluna-formulario-agentes">
                <div class="container px-0">

                    <div class="row px-1">
                        <div class="col-12">
                            <div class="container">


                                <div class="row" v-if="titulo_interno">
                                    <div class="col-10 mx-auto my-5">
                                        <h3 class="mb-form-agentes-titulo-interno"> {{ titulo_interno }} </h3>
                                    </div>
                                </div>

                                <div class="row py-2">
                                    <div class="col-12 pr-2">
                                        <input v-model="cep" type="text" class="form-agentes-input-cep" placeholder="Insira seu CEP" >
                                    </div>
                                </div>

                                <div class="row py-2">
                                    <div class="col-10 pr-2">
                                        <select v-model.number="produto" class="form-agentes-select-produto">
                                            <option :value="0">Escolha um produto Omni</option>
                                            <option
                                                v-for="(servico, i) in servicosDisponiveis"
                                                v-bind:key="i"
                                                :value="servico.id"
                                            >
                                                {{ servico.nome }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-2 px-0">
                                        <botao-p 
                                            @click="buscaAgente"
                                            background_color="var(--cinza-claro-omni)"
                                            largura="3.4rem" altura="3.5rem"
                                        />
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <hr class="separador-form-agentes-rodape">
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <p class="texto-pequeno-form-agentes-rodape">Quer ser um Correspondente Bancário Omni?</p>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <router-link to="/seja-correspondente">
                                            <botao-m class="mb-form-agentes-botao-m">
                                                É só você clicar aqui.
                                            </botao-m>
                                        </router-link>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>

    </div>
  </div>
</template>


<script>
import BotaoP from '@/components/botoes/BotaoP.vue';
import { mapGetters } from 'vuex';
import BotaoM from '@/components/botoes/BotaoM';

// 'Grupo de máscara 1'
export default {
  name: 'MbFormAgentes',
  components: {
      BotaoP,
      BotaoM,
  },
  props: {
    id_comp: Number,
    componente: Object,
  },
  methods: {
      atualizaProdutoSelecionado: function(pValor) {
          this.produto_selecionado = pValor;
      },
      buscaAgente: function() {
        // Limpa caracteres nao numericos
        let cepNumeros = this.cep.replace(/\D/g,'');
        this.$router.push({ name: 'ResultadoAgentesPage', query: { cep: cepNumeros, produto: this.produto } });
      },
  },
  computed: {
        ...mapGetters({
            servicosDisponiveis: 'servicos/servicos',
        }),
      titulo() {
        if(this.componente) {
            if(this.componente.titulo) {
                return this.componente.titulo;
            }
        }
        return '';
      },
      titulo_interno() {
        if(this.componente) {
            if(this.componente.texto) {
                return this.componente.texto;
            }
        }
        return 'Localize o Agente mais próximo para contratar um produto Omni.';
      },
      produto: {
        get() {
            if(this.produto_selecionado !== 0) {
                return this.produto_selecionado;
            }

            if(this.componente) {
                if(this.componente.int_geral) {
                    this.atualizaProdutoSelecionado(this.componente.int_geral);
                    return this.componente.int_geral;
                }
            }
            return 0;
        },
        set(pValor) {
            this.produto_selecionado = pValor;
        }
      } 
  },
  data: function() {
    return {
        produto_selecionado: 0,
        cep: '',
    }
  },
}


</script>

<style scoped>
.mb-form-agentes-container {
    max-width: 100vw;
    width: 100% !important;
    margin-top: 28px;
    margin-bottom: 28px;
    min-height: 700px;
}

.coluna-formulario-agentes {
    background-color: var(--azul-claro-omni);
    padding-bottom: 36px;
}

.mb-form-agentes-titulo-interno {
    color: #fff;
    text-align: center;
    font-weight: 400;
}

.texto-pequeno-form-agentes-rodape {
    color: #fff;
    text-align: center;
}

.form-agentes-input-cep,
.form-agentes-select-produto {
    border-radius: 8px;
    outline: none;
    border: none;
    height: 3.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: medium;
    width: 100%;
}

.form-agentes-select-produto {
    color: var(--azul-claro-omni);
    background-color: white;
    -webkit-appearance: caret;
    -webkit-appearance: none;
}

    select {
        background-color: white;
        -webkit-appearance: caret;
        -webkit-appearance: none;
    }

.separador-form-agentes-rodape {
    background-color: #fff;
    width: 100%;
}

.mb-form-agentes-img-container {
    max-width: 300px !important;
    width: 100% !important;
    position: relative;
    bottom: -4px;
    z-index: 2;
    margin: auto;
}

.mb-form-agentes-img {
    height: auto;
    width: 100% !important;
}

.mb-form-agentes-botao-m {
    width: 100% !important;
    height: 68px !important;
}

</style>