<template>
    <div class="mb-rows-centralizadas-container" :style="{ 'background-color': corFundo }">
        <div class="container py-3">
            <div class="row mb-rows-centralizadas-row px-3" v-if="componente.titulo">
                <h3 class="mb-rows-centralizadas-titulo" :style="{ 'color': corTitulo }">{{ componente.titulo }}</h3>
            </div>

            <div class="row mb-rows-centralizadas-row pb-1 pt-2" v-if="urlImagem">
                <div class="col-7 mx-auto">
                    <div class="mb-rows-centralizadas-img-container">
                        <img class="mb-rows-centralizadas-img" :src="urlImagem" alt="icone"/>
                    </div>
                </div>
            </div>

            <div class="row mb-rows-centralizadas-row py-1" v-if="temBotao1">
                <botao-m class="px-4 py-3 mb-rows-centralizadas-botao" @click="redireciona(componente.url_botao_1)" :style="{ 'background-color': corBotao1 }"> {{textoBotao1}} </botao-m>
            </div>

            <div class="row mb-rows-centralizadas-row py-1 px-3" v-if="componente.sub_titulo">
                <h4 class="mb-rows-centralizadas-sub-titulo" :style="{ 'color': corSubTitulo }">{{ componente.sub_titulo }}</h4>
            </div>

            <div class="row mb-rows-centralizadas-row py-1" v-if="componente.texto">
                <span v-html="componente.texto" :style="{ 'color': corTexto }" class="conteudo-html-components"></span>
            </div>

            <div class="row mb-rows-centralizadas-row" v-if="temBotao2">
                <botao-m class="px-4 py-3 mb-rows-centralizadas-botao" @click="redireciona(componente.url_botao_2)" :style="{ 'background-color': corBotao2 }"> {{textoBotao2}} </botao-m>
            </div>
        </div>
    </div>
</template>
<script>
import { getCor } from '@/assets/js/SharkUtils';
import { redirecionadorExterno } from '@/assets/js/SharkUtils';
import BotaoM from '@/components/botoes/BotaoM';

export default {
    name: 'MbRowsCentralizadas',
    components: {
        BotaoM,
    },
    props: {
        id_comp: Number,
        componente: Object,
    },
    computed: {
        corFundo() {
            if(this.componente) {
                if(this.componente.tema) {
                    let corLista = getCor(this.componente.tema);
                    if(corLista) {
                        return corLista.var_hexa;
                    }
                }
            }
            return '#fff';
        },
        corTexto() {
            return '#000';
        },
        temBotao1() {
            if(this.componente) {
                if(this.componente.txt_botao_1) {
                    return this.componente.txt_botao_1.length > 0 ? true: false;
                }
            }
            return false;
        },
        textoBotao1() {
            if(this.componente) {
                if(this.componente.txt_botao_1) {
                    return this.componente.txt_botao_1
                }
            }
            return 'Clique aqui!';
        },
        corBotao1() {
            if(this.componente) {
                if(this.componente.cor_botao_1) {
                    let corLista = getCor(this.componente.cor_botao_1);
                    if(corLista) {
                        return corLista.var_hexa;
                    }
                }
            }
            return 'var(--laranja-omni)';
        },
        temBotao2() {
            if(this.componente) {
                if(this.componente.txt_botao_2) {
                    return this.componente.txt_botao_2.length > 0 ? true: false;
                }
            }
            return false;
        },
        textoBotao2() {
            if(this.componente) {
                if(this.componente.txt_botao_2) {
                    return this.componente.txt_botao_2
                }
            }
            return 'Clique aqui!';
        },
        corBotao2() {
            if(this.componente) {
                if(this.componente.cor_botao_2) {
                    let corLista = getCor(this.componente.cor_botao_2);
                    if(corLista) {
                        return corLista.var_hexa;
                    }
                }
            }
            return 'var(--laranja-omni)';
        },
        urlImagem() {
            if(this.componente) {
                if(this.componente.url_img) {
                    return this.componente.url_img;
                }
            }
            return '';
        },
        corTitulo() {
            if(this.componente) {
                return getCor(this.componente.cor_titulo).var_hexa;
            }
            return '#000';
        },
        corSubTitulo() {
            if(this.componente) {
                return getCor(this.componente.cor_sub_titulo).var_hexa;
            }
            return '#000';
        },        
    },
    data: function() {
        return {
        }
    },
    methods: {
        redireciona(pUrl) {
            if(pUrl) {
                redirecionadorExterno(this.$router, pUrl);
            }
        },
    },
}
</script>
<style scoped>
.mb-rows-centralizadas-container{
    max-width: 100vw !important;
    width: 100% !important;
    overflow-x: hidden;
    margin-top: 28px;
    margin-bottom: 28px;
}

.mb-rows-centralizadas-img-container {
    width: 100%;
    max-width: 250px;
    margin: auto;
}
.mb-rows-centralizadas-img {
    width: 100%;
    height: auto;
}

.mb-rows-centralizadas-row > * {
    margin: 5px auto;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 20px;
    padding-right: 20px;
}

.mb-rows-centralizadas-botao {
    height: auto !important;
    width: auto !important;
    max-width: 90%;
    overflow-x: hidden;
    margin-bottom: 10px;
}

.mb-rows-centralizadas-titulo {
    font-weight: 900;
    text-align: center;
}

.mb-rows-centralizadas-sub-titulo {
    font-weight: 900;
    text-align: center;
}

</style>