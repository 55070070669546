<template>
    <div id="app">
        <mb-header-menu v-if="adminLogado" />
        <router-view />
        <mb-footer v-if="adminLogado" />
        <popup v-if="adminLogado" />
    </div>
</template>

<script>
import MbHeaderMenu from '@/mobile/components/MbHeaderMenu.vue'
import MbFooter from '@/mobile/components/MbFooter.vue'
import Popup from '../components/Popup.vue'
import { mapGetters, mapActions } from 'vuex';
import { criaHandTalk } from '@/assets/js/SharkUtils';

export default {
    name: 'App',
    components: {
        MbHeaderMenu,
        MbFooter,
        Popup
    },
    methods: {
        ...mapActions({
            getSetores: 'setoresMenu/getSetores',
            getHome: 'paginas/getHome',
            getPaginas: 'paginas/getPaginas',
            getServicos: 'servicos/getServicos',
            getFormContato: 'formContato/getFormContato',
        }),
    },
    computed: {
        ...mapGetters({
            showOverlay: 'overlay',
            adminLogado: 'authAdmin/admAuth'
        })
    },
    created() {
        this.getSetores();
        if(this.$route.path != "/") {
            this.getPaginas()
        } else {
            // Busca primeiro a home e atrasa a busca do resto das paginas
            this.getHome()
            setTimeout(() => this.getPaginas(), 3000)
        }
    },
    mounted() {
        this.$nextTick( () => {
            this.getFormContato();
            this.getServicos();
            setTimeout(() => criaHandTalk(), 2500);
        })
    }
}
</script>

<style>
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    #app {
        font-family: MuseoSans;
        display: grid;
    }

    @font-face {
        font-family: MuseoSans;
        src: url(../assets/fonts/MuseoSans-100.otf);
        font-weight: 100;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: MuseoSans;
        src: url(../assets/fonts/MuseoSans-300.otf);
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: MuseoSans;
        src: url(../assets/fonts/MuseoSans_500.otf);
        font-weight: bold;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: MuseoSans;
        src: url(../assets/fonts/MuseoSans_500.otf);
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: MuseoSans;
        src: url(../assets/fonts/MuseoSans_700.otf);
        font-weight: 700;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: Museo;
        src: url(../assets/fonts/MuseoSans-900.otf);
        font-weight: 900;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: MuseoSans;
        src: url(../assets/fonts/MUSEOSANS-900-nova.OTF);
        font-weight: 900;
        font-style: normal;
        font-display: swap;
    }
    
    @font-face {
        font-family: Neutra-Display;
        src: url(../assets/fonts/Neutra-Display.otf);
        font-display: swap;
    }

    .conteudo-html-components p {
        font-weight: normal;
        line-height: normal;
        margin-bottom: 0.3rem !important;
    }

    .modal-backdrop {
        opacity: 0.4;
    }
</style>
