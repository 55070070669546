<template>
    <div class="mb-linha-produtos-container" :style="{ 'background-color': corFundo }">
        <div class="container-fluid">

            <div class="row px-2 mt-2">
                <h2 class="mb-linha-produtos-titulo" :style="{ 'color': corTitulo }">{{componente.titulo}}</h2>
            </div>
            <div class="row mb-3">
                <h5 class="mb-linha-produtos-sub-titulo" :style="{ 'color': corSubTitulo }" >{{componente.sub_titulo}}</h5>
            </div>

            <div class="row mt-2">
                <div class="col-12">
                    <div class="mb-linha-produtos-slick-wrapper">
                    <div class="mb-linha-produtos-slick-container">
                        <VueSlickCarousel 
                        v-bind="settings"
                        >
                            <div class="mb-linha-produtos-container-produto px-2 py-2" v-for="(item, index) in itens" v-bind:key="index">
                                <linha-produtos-caixa 
                                    :titulo="item.titulo"
                                    :urlImg="item.url_icone"
                                    :urlBotao="item.url_redir"
                                />
                            </div>

                            <template #customPaging>
                                <div class="mb-linha-produtos-dots-class">
                                </div>
                            </template>

                        </VueSlickCarousel>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LinhaProdutosCaixa from '@/components/home-components/LinhaProdutosCaixa';
import { getCor } from '@/assets/js/SharkUtils';
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
    name: 'MbLinhasProdutos',
    components: {
        LinhaProdutosCaixa,
        VueSlickCarousel,
    },
    props: {
        id_comp: Number,
        componente: Object,
        dotClassProdutos: {
            type: String,
            default: 'mb-linha-produtos-dots-class'
        },
    },
    computed: {
        itens() {
            if(this.componente) {
                return this.componente.itens? this.componente.itens: [];
            } else {
                return [];
            }
        },
        corTitulo() {
            if(this.componente) {
                return getCor(this.componente.cor_titulo).var_hexa;
            }
            return '#000';
        },
        corSubTitulo() {
            if(this.componente) {
                return getCor(this.componente.cor_sub_titulo).var_hexa;
            }
            return '#000';
        },
        corFundo() {
            if(this.componente) {
            return getCor(this.componente.tema).var_hexa;
            }
            return '#fff';
        },
    },
    data: function () {
        return {
            settings: {
                lazyLoad: "ondemand",
                dots: true,
                focusOnSelect: true,
                infinite: false,
                speed: 400,
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 0,
                touchThreshold: 7,
            }
        }
    },
    methods: {
        
    },
}

</script>

<style>
.mb-linha-produtos-container {
    width: 100%;
    max-width: 100vw;
    padding-top: 10px;
    padding-bottom: 20px;
    min-height: 400px;
}

.mb-linha-produtos-titulo {
    text-align: center;
    margin: 0 auto;
    font-weight: 900;
    font-size: 30px;
}

.mb-linha-produtos-sub-titulo {
    text-align: center;
    margin: 0 auto;
}

.mb-linha-produtos-dots-class {
    background-color: var(--cinza-omni);
    height: 6px;
    border-radius: 3px;
    width: 25px;
}
.slick-active > .mb-linha-produtos-dots-class {
    background-color: var(--azul-claro-omni);
}

.mb-linha-produtos-arrow {
    height: 20px;
    width: 20px;
}
.mb-linha-produtos-arrow::before {
    content: none;
}

.mb-linha-produtos-slick-wrapper {
    max-width: 100vw !important;
    overflow-x: hidden;
    overflow-y: hidden;
    padding-bottom: 40px;
    padding-left: 4px;
    padding-right: 4px;
    margin: 0 auto;
}

.mb-linha-produtos-slick-container {
    max-width: calc(100% - 2px) !important;
    margin: 0 auto;
}

</style>

