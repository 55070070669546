<template>
   <div class="caixa-footer">
       <div class="container-fluid mb-4" v-if="carregouPaginas">

           <div class="row mt-2">
               <div class="col-12 ">
                   <div class="mb-footer-container-logo">
                       <b-img-lazy
                            fluid
                            src="@/assets/images/novo_omni_logo_RGB.png"
                            alt="Logo Omni" 
                        >
                        </b-img-lazy>
                   </div>
                   <br />
                   <div>
                       <p class="mb-footer-txt-endereco mx-auto">
                           Av. São Gabriel, 555 - 5º andar,<br />
                           Jardim Paulista, São Paulo - SP<br />
                           CEP 01435-001<br />
                           CNPJ 92.228.410/0001-02
                       </p>
                   </div>
                </div>
            </div>

            <div class="row justify-content-center py-3 my-3">
                <div class="col-xs-10 col-sm-8">
                    <div class="row" v-if="dadosSociaisRodape" >
                        <div class="col-3 px-1" style="text-align:center;" v-for="(dadoSocial, index) in dadosSociaisRodape"  v-bind:key="index">
                            <a :href="'https://' + dadoSocial.url" target="_blank">
                                <b-img-lazy
                                    class="w-75"
                                    fluid
                                    :src="dadoSocial.url_icone" 
                                    :alt="dadoSocial.titulo" 
                                >
                                </b-img-lazy>
                            </a>
                        </div>
                    </div>
               </div>
            </div>

            <div class="row">
               <div class="col-xs-12 col-sm-10 mx-sm-auto">
                    <mb-footer-caixa-expandivel 
                        v-for="(dado, index) in primeirasCols" v-bind:key="index"
                        :titulo="dado.titulo"
                    >
                        <div class="my-0 mb-footer-txt-caixas conteudo-html-components"  v-html="dado.conteudo"></div>
                    </mb-footer-caixa-expandivel>
               </div>
            </div>

            <div class="row my-2">
               <div class="col-10 mx-auto" v-if="ultimaCol">
                    <div class="mb-footer-container-deficientes my-2">
                        <img src="@/assets/images/icone-surdo.png" width="50" class="mx-auto mb-footer-img-deficientes"/>
                    </div>
                    <div class="my-3">
                        <p class="mb-0 mb-footer-txt-laranja">{{ultimaCol.titulo}}</p>
                        <div class="my-2 mb-footer-txt-deficientes conteudo-html-components" v-html="ultimaCol.conteudo"></div>
                    </div>
               </div>
            </div>

            <div class="row d-flex justify-content-center p-2 px-sm-4 px-lg-5">
                <div class="col-5">
                    <b-img-lazy 
                        fluid-grow
                        src="@/assets/images/Selo_Melhores_GPTW-2020.png"
                        alt="Melhores para trabalhar"
                    >
                    </b-img-lazy>
                </div>

                <div class="col-6 d-flex align-items-center">
                    <b-img-lazy 
                        fluid-grow
                        src="@/assets/images/selocc.png"
                        alt="Selocc"
                    >
                    </b-img-lazy>
                </div>

                <div class="col-8 mx-auto mt-3 d-flex align-items-center">
                    <b-img-lazy 
                        fluid-grow
                        src="@/assets/images/consumidor.gov.png"
                        alt="Consumidor.gov.br"
                    >
                    </b-img-lazy>
                </div>
            </div>

            <div class="row mt-4">
               <div class="col-10 mx-auto">
                    <p class="mb-0 mb-footer-p-centralizado"> © 2020 Omni Banco &amp; Financeira. </p>
                    <p class="mb-2 mb-footer-p-centralizado"> Todos os direitos reservados. </p>
               </div>
            </div>

       </div>

       <div class="container-fluid mb-4" v-if="!carregouPaginas" style="min-heigth: 800px;">
           <div class="row mt-4">
               <div class="col-10 mx-auto">
                    <p class="mb-0 mb-footer-p-centralizado"> © 2020 Omni Banco &amp; Financeira. </p>
                    <p class="mb-2 mb-footer-p-centralizado"> Todos os direitos reservados. </p>
               </div>
            </div>
       </div>
       
   </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MbFooterCaixaExpandivel from '@/mobile/components/MbFooterCaixaExpandivel.vue'

export default {
    name: 'MbFooter',
    components: {
        MbFooterCaixaExpandivel,
    },
    data: function() {
        return {
        }
    },
    methods: {
        ...mapActions({
        getDadosRodape: 'dadosRodape/getDadosRodape',
        getDadosSociaisRodape: 'dadosRodape/getDadosSociaisRodape'
        }),
    },
    computed: {
        ...mapGetters({
            dadosRodape: 'dadosRodape/dadosRodape',
            dadosSociaisRodape: 'dadosRodape/dadosSociaisRodape',
            listaPaginas: 'paginas/paginas',
        }),
        ultimaCol() {
            if(this.dadosRodape) {
                return this.dadosRodape[this.dadosRodape.length - 1];
            } else {
                return null;
            }
        },
        primeirasCols(){
            if(this.dadosRodape) {
                return this.dadosRodape.slice(0, this.dadosRodape.length - 1);
            } else {
                return [];
            }
        },
        carregouPaginas() {
            if(!this.listaPaginas || this.listaPaginas.length == 0) {
                return false
            }
            return true
        },
    },
    mounted() {
        this.getDadosRodape();
        this.getDadosSociaisRodape();
    }
    
}

</script>

<style scoped>
    .caixa-footer {
        background-color: #E2E2E2;
        text-align: left;
        padding: 16px;
    }

    .mb-footer-txt-endereco {
        color: var(--chumbo-omni);
        font-family: MuseoSans;
        font-size: 13px;
        margin: 2px auto;
        text-align: center;
    }

    .mb-footer-txt-laranja {
        color: var(--laranja-claro-omni);
        font-family: MuseoSans;
        font-size: 13px;
        text-align: center;
        font-weight: bold;
    }

    .mb-footer-txt-deficientes {
        color: var(--chumbo-omni);
        font-family: MuseoSans;
        font-size: 13px;
        text-align: center;
    }
    .mb-footer-txt-caixas {
        color: var(--chumbo-omni);
        font-family: MuseoSans;
        font-size: 13px;
        margin-bottom: 2px;
    }

    .mb-footer-p-centralizado {
        text-align: center;
    }

    .footer-icon-redesocial {
        width: 40px;
    }
        .footer-icon-redesocial:hover {
            filter: brightness(80%);
        }

.mb-footer-container-logo {
    width: 40%;
    margin: 0 auto;
}


.mb-footer-container-deficientes {
    width: 50px;
    margin: 0 auto;
}

.mb-footer-img-deficientes {
    width: 100%;
    height: auto;
}

@media screen and (min-width: 650px) and (max-width: 1050px) {
    .caixa-footer {
        padding-left: 26px;
        padding-right: 26px;
    }
}

</style>
