<template>
    <div class="mb-banner-principal-container" v-bind:style="{ 'background-color': corFundo }">
        <div class="container-fluid" v-if="componente">

        <div class="row" v-if="componente.url_img_mb? componente.url_img_mb.length > 0: false">
            <div class="mb-banner-principal-img-mb-container">
                <img :src="componente.url_img_mb" class="mb-banner-principal-img-mb"/>
            </div>
        </div>
        <div class="row" v-else>
            <div class="mb-banner-principal-img-container">
                <img :src="urlImagem" class="mb-banner-principal-img"/>
            </div>
        </div>

        <div class="row px-3 pt-3">
            <h1 class="mb-banner-principal-titulo" :style="{ 'color': corTitulo }" >{{componente.titulo}}</h1>
        </div>
        <div class="row px-3 pb-3">
            <h1 class="mb-banner-principal-titulo" :style="{ 'color': corSubTitulo }">{{componente.sub_titulo}}</h1>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="conteudo-html-components banner-principal-conteudo px-3" v-html="componente.texto"> </div>
                <botao-m class="banner-principal-botao" v-if="temBotao1" @click="redireciona(componente.url_botao_1)" :style="{ 'background-color': corBotao1 }"> {{textoBotao1}} </botao-m>
                <botao-m class="banner-principal-botao" v-if="temBotao2" @click="redireciona(componente.url_botao_2)" :style="{ 'background-color': corBotao2 }"> {{textoBotao2}} </botao-m>
            </div>
        </div>

        </div>
    </div>
</template>

<script>
import { temasFactory, getCor } from '@/assets/js/SharkUtils';
import { LISTA_CORES } from '@/assets/js/dicionarioPaginas';
import { redirecionadorExterno } from '@/assets/js/SharkUtils';
import BotaoM from '@/components/botoes/BotaoM';

export default {
    name: 'BannerPrincipal',
    components: {
        BotaoM,
    },
    props: {
        id_comp: Number,
        componente: Object,
    },
    computed: {
        tema() {
            if(this.componente) {
                return temasFactory(this.componente.tema);
            }
            return null;
        },
        corFundo() {
            if(this.componente) {
                return getCor(this.componente.tema).var_hexa;
            }
            return '#000';
        },
        corTitulo() {
            if(this.componente) {
                if(this.componente.cor_titulo) {
                    let corLista = this.getCorLista(this.componente.cor_titulo);
                    if(corLista) {
                        return corLista.var_hexa;
                    }
                }
            }
            return 'var(--azul-omni)';
        },
        corSubTitulo() {
            if(this.componente) {
                if(this.componente.cor_sub_titulo) {
                    let corLista = this.getCorLista(this.componente.cor_sub_titulo);
                    if(corLista) {
                        return corLista.var_hexa;
                    }
                }
            }
            return 'var(--azul-omni)';
        },
        temBotao1() {
            if(this.componente) {
                if(this.componente.txt_botao_1) {
                    return this.componente.txt_botao_1.length > 0 ? true: false;
                }
            }
            return false;
        },
        textoBotao1() {
            if(this.componente) {
                if(this.componente.txt_botao_1) {
                    return this.componente.txt_botao_1
                }
            }
            return 'Clique aqui!';
        },
        corBotao1() {
            if(this.componente) {
                if(this.componente.cor_botao_1) {
                    let corLista = this.getCorLista(this.componente.cor_botao_1);
                    if(corLista) {
                        return corLista.var_hexa;
                    }
                }
            }
            return 'var(--laranja-omni)';
        },
        temBotao2() {
            if(this.componente) {
                if(this.componente.txt_botao_2) {
                    return this.componente.txt_botao_2.length > 0 ? true: false;
                }
            }
            return false;
        },
        textoBotao2() {
            if(this.componente) {
                if(this.componente.txt_botao_2) {
                    return this.componente.txt_botao_2
                }
            }
            return 'Clique aqui!';
        },
        corBotao2() {
            if(this.componente) {
                if(this.componente.cor_botao_2) {
                    let corLista = this.getCorLista(this.componente.cor_botao_2);
                    if(corLista) {
                        return corLista.var_hexa;
                    }
                }
            }
            return 'var(--laranja-omni)';
        },
        urlImagem() {
            if(this.componente) {
                if(this.componente.url_img) {
                    return this.componente.url_img;
                }
            }
            return require('../../../assets/images/placeholders/img_placeholder.png');
        },
    },
    data: function() {
        return {
            listaCores: LISTA_CORES,
        }
    },
    methods: {
        getCorLista: function(COR) {
            return this.listaCores.find(cor => cor.chave === COR);
        },
        redireciona(pUrl) {
            if(pUrl) {
                redirecionadorExterno(this.$router, pUrl);
            }
        },
        estilizaComponentes: function() {
            let tituloEl = this.$el.querySelector(".banner-principal-body-class > h1");
            if(tituloEl) {
                tituloEl.style.color = this.corTitulo;
            }
        },
    },
    mounted() {
        this.estilizaComponentes();
    },
    updated() {
        this.estilizaComponentes();
    },
}
</script>

<style scoped>
.mb-banner-principal-container {
    margin-bottom: 28px;
    max-width: 100% !important;
    width: 100% !important;
    padding-bottom: 20px;
}

.mb-banner-principal-img-mb-container {
    max-width: 100% !important;
    width: 100% !important;
}

.mb-banner-principal-img-mb {
    height: auto;
    width: 100% !important;
}

.mb-banner-principal-img-container {
    max-width: 100% !important;
    width: 100% !important;
    position: relative;
    overflow: hidden;
    height: 400px;
}

.mb-banner-principal-img {
    height: 400px;
    width: auto;
    position: absolute;
    right: -35%;
    top: 0;
}
@media screen and (min-width: 650px) and (max-width: 1050px) {
    .mb-banner-principal-img {
        right: 0;
    }
}

.mb-banner-principal-titulo {
    margin: 0px auto;
    text-align: center;
    font-weight: 900;
    font-size: 32px;
}

.banner-principal-conteudo {
    line-height: normal;
    font-size: 14px;
    text-align: center;
}

.banner-principal-botao {
    display: block;
    width: 96% !important;
    height: auto !important;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: auto !important;
    margin-right: auto !important;
    padding-top: 14px !important;
    padding-bottom: 14px !important;
    padding-right: 8px !important;
    padding-left: 8px !important;
}

</style>