<template>
  <div class="home-page-container">
    <div class="container-fluid" v-if="carregouPaginas">

        <div class="row" 
        v-for="(comp, index) in componentes" 
        :key="index" 
        :id="comp && comp.posicao !== undefined && comp.posicao !== null? `parte-${comp.posicao}`: null"
        >
            <component
                :is="comp.tipo"
                v-bind:key="index"
                :componente="comp"
                :id_comp="comp.id"
            ></component>
        </div>
        
    </div>

    <div class="container-fluid" style="min-height: 100vh;" v-else>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// Mobile
import MbBannerPrincipal from '@/mobile/components/page-components/MbBannerPrincipal.vue';
import MbImgEsqDir from '@/mobile/components/page-components/MbImgEsqDir.vue';
import MbFormAgentes from '@/mobile/components/page-components/MbFormAgentes.vue';
const MbFormContato = () => import('@/mobile/components/page-components/MbFormContato.vue');
import MbListaIcones from '@/mobile/components/page-components/MbListaIcones.vue';
import MbRowsCentralizadas from '@/mobile/components/page-components/MbRowsCentralizadas.vue';
import MbFaixaTextoImagem from '@/mobile/components/page-components/MbFaixaTextoImagem.vue';
import MbColunasBotaoImg from '@/mobile/components/page-components/MbColunasBotaoImg.vue';
// Responsivos
// Novos para home
import BannersHome from '@/components/home-components/BannersHome.vue'
import MbLinhaProdutos from '@/mobile/components/home-components/MbLinhaProdutos.vue'
import MbValidaBoleto from '@/mobile/components/home-components/MbValidaBoleto.vue'
import MbUltimasNoticias from '@/mobile/components/home-components/MbUltimasNoticias.vue'
import MbImgDireitaHome from '@/mobile/components/home-components/MbImgDireitaHome.vue'
import MbImgEsquerdaHome from '@/mobile/components/home-components/MbImgEsquerdaHome.vue'
//--
import MapaGoogle from '@/components/page-components/MapaGoogle.vue';

export default {
    name: 'MbHomePage',
    components: {
        "BANNER_PADRAO": MbBannerPrincipal,
        "IMG_DIREITA": MbImgEsqDir,
        "LISTA_ICONES": MbListaIcones,
        "LISTA_ICONES_ESQ": MbListaIcones,
        "IMG_ESQUERDA": MbImgEsqDir,
        "AGENTES": MbFormAgentes,
        "CONTATO": MbFormContato,
        "ROWS_CENTRALIZADAS": MbRowsCentralizadas,
        "IMAGEM_FAIXA_TEXTO": MbFaixaTextoImagem,
        "COLUNAS_BOTAO_IMG": MbColunasBotaoImg,
        "LISTA_CARROSSEL": MbListaIcones,
        "BANNER_HOME": BannersHome,
        "LINHA_PRODUTOS": MbLinhaProdutos, 
        "VALIDA_BOLETO": MbValidaBoleto,
        "ULTIMAS_NOTICIAS": MbUltimasNoticias,
        "MAPA_GOOGLE": MapaGoogle,
        "IMG_DIREITA_HOME": MbImgDireitaHome,
        "IMG_ESQUERDA_HOME": MbImgEsquerdaHome,
    },
    computed: {
        ...mapGetters({
            listaPaginas: 'paginas/paginas',
        }),
        pagina: function() {
            let pagina = this.listaPaginas? 
                            this.listaPaginas
                                .find(pagina => {
                                    return pagina.template === 'HOME';
                                })
                            : {};

            return pagina; 
        },
        componentes: function() {
            if(this.pagina) {
                return this.pagina.componentes? this.pagina.componentes: [];
            } else {
                return [];
            }
        },
        carregouPaginas() {
            if(!this.listaPaginas || this.listaPaginas.length == 0) {
                return false
            }
            return true
        },
    },
}
</script>

<style>
    .home-page-container {
        max-width: 100vw;
    }
</style>
