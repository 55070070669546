<template>
    <div class="mb-valida-boleto-container">
        <div class="container-fluid">

            <div class="row py-3">
                <div class="col-12" style="background-color:#fff;">
                    <div class="mb-valida-boleto-container-img">
                        <img src="@/assets/images/img_boleto.svg" class="mb-valida-boleto-img"/>
                    </div>
                </div>
            </div>

            <div class="row">

                <div class="col-12" style="background-color:var(--azul-claro-omni); color: #fff; min-height:430px;">
                    <div class="container-fluid mb-valida-boleto-alinha-vertical pt-3 pb-3 px-3">

                        <div class="row">
                            <h2 class="mb-valida-boleto-texto-700 mx-auto mb-0 text-center">Não caia em fraudes.</h2>
                        </div>
                        <div class="row pb-2">
                            <h2 class="mb-valida-boleto-texto-700 mx-auto text-center">Valide aqui seu boleto da Omni.</h2>
                        </div>
                        <div class="row pb-2">
                            <p class="mb-valida-boleto-texto-500 mx-auto text-center">Digite o código numérico do seu boleto para verificar a autenticidade:</p>
                        </div>

                        <div class="row">
                            <div class="col-xs-12 col-sm-10 col-md-9 col-lg-8 mx-sm-auto">
                                <input-boleto @click="validaBoleto" />
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import InputBoleto from '@/components/inputs/InputBoleto.vue';

export default {
    name: 'ValidaBoleto',
    components: {
        InputBoleto,
    },
    props: {
        id_comp: Number,
        componente: Object,
    },
    computed: {
    },
    data: function () {
        return {
        }
    },
    methods: {
        validaBoleto: function (resultadoValidacao) {
            const boletoValido = resultadoValidacao?.encontrouBoleto;

            let options = {
                title: resultadoValidacao?.mensagemCliente,
                size: 'sm',
                buttonSize: 'lg',
                okVariant: boletoValido === true ? 'success' : 'danger',
                okTitle: 'Entendi',
                dialogClass: 'px-3',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true,
            };

            this.$store.dispatch('showOverlay', false);

            if (resultadoValidacao.status.toUpperCase() === "FAIL") {
                this.$bvModal.msgBoxOk(resultadoValidacao.messages[0], options)
                return;
            }

            if (resultadoValidacao.status.toUpperCase() === "SUCCESS") {
                if (!resultadoValidacao.encontrouBoleto) {
                    let msgModalInvalido = `Verifique se a digitação está correta ou entre em contato com nosso SAC 
                                para mais informações: 0800 727 0885 (Atendimento para todos os produtos, 24 horas, 7 dias por semana). 
                                Se preferir, fale direto com o Correspondente Bancário Omni da sua região.`

                    this.$bvModal.msgBoxOk(msgModalInvalido, options)
                    return;
                }

                if (resultadoValidacao.encontrouBoleto) {
                    let msgModalValido = 'Você pode efetuar o pagamento deste boleto com segurança.'

                    this.$bvModal.msgBoxOk(msgModalValido, options)
                    return;
                }
            }
            return;
        },
    },
}

</script>

<style scoped>
.mb-valida-boleto-container {
    width: 100%;
    max-width: 100%;
    margin-top: 28px;
    margin-bottom: 28px;
    min-height: 600px;
}

.mb-valida-boleto-row-resultado {
    min-height: 50px;
}

.mb-valida-boleto-container-img {
    position: relative;
    max-width: 100%;
    min-width: 200px;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-left: 15px !important;
}

.mb-valida-boleto-img {
    width: 100%;
    height: auto;
}

.mb-valida-boleto-texto-500 {
    font-weight: bold;
}

.mb-valida-boleto-texto-700 {
    font-weight: 700;
}

.mb-valida-boleto-alinha-vertical {
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

</style>

